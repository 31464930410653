import PasswordLostForm from '@/auth/components/PasswordLostForm'

const PasswordLost = () => {
  return (
    <div className="container min-h-screen">
      <div className="flex flex-wrap w-3/4 mx-auto mb-5 mt-8">
        <div className="basis-full md:basis-1/2 md:pr-6 mb-5 md:mb-0 mx-auto">
          <PasswordLostForm />
        </div>
      </div>
    </div>
  )
}

export default PasswordLost