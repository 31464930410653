import { FC } from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../ui/breadcrumb"

const ReactBreadcrumb:FC<{items:{name: string;href?: string;}[]}> = ({items}) => {
  if (items.length <= 0) {
    return ;
  }
  return (
    <Breadcrumb>
      <BreadcrumbList>
        {items.map((item:{name:string;href?: string}, key:number) => 
            key + 1 == items.length ? 
              <BreadcrumbItem key={key}>
                  <BreadcrumbPage className="font-medium text-primary text-base">{item.name}</BreadcrumbPage>
              </BreadcrumbItem>
            : 
            <div key={key} className="inline-flex items-center gap-1.5">
                <BreadcrumbItem >
                    <BreadcrumbLink href={item.href} className="font-medium text-foreground opacity-70 text-base">{item.name}</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator key={key+'sep'} />
            </div>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default ReactBreadcrumb