import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Eye, EyeOff } from "lucide-react";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

const formSchema = z.object({
    email: z.string().email({ message: "Adresse email invalide" }),
  });
const PasswordLostForm = () => {
    const { signIn, loading } = useAuth();
    const navigate = useNavigate();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
          email: "",
        },
      });
    const { setError } = form;
    async function onSubmit(values: z.infer<typeof formSchema>) {
        const { success } = await signIn(values.email, values.password);
        if (success) {
          toast({
            title: "Vous êtes connecté",
          });
          navigate(`/programs`);
          window.location.reload();
        } else {
          // Déclenche une erreur pour le champ mot de passe
          setError("password", {
            type: "server",
            message: "Email ou mot de passe incorrect",
          });
        }
      }
    return (
        <Card>
        <CardHeader>
            <CardTitle>Trouvez votre compte</CardTitle>
            <CardDescription>
                Veuillez entrer votre e-mail ou votre numéro de mobile pour rechercher votre compte.
            </CardDescription>
        </CardHeader>
        {loading ? (
            "loading..."
        ) : (
            <CardContent>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                    <FormItem>
                        <FormLabel>Mail</FormLabel>
                        <FormControl>
                        <Input placeholder="Mail" {...field} type="email" />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                    )}
                />
                <Button type="submit" variant={"default"} className="w-full">
                    Rechercher
                </Button>
                </form>
            </Form>
            </CardContent>
        )}
        </Card>
    )
}

export default PasswordLostForm